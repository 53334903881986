import "../css/style.scss";

const scrolLDownButtons = document.querySelectorAll(".scroll-down");

scrolLDownButtons.forEach((button) => {
  button.addEventListener("click", () => {
    const height = window.innerHeight;
    window.scrollTo({
      top: height,
      behavior: "smooth",
    });
  });
});

const links = document.querySelectorAll("[href]");

links.forEach((link) => {
  if (link.href == window.location.href) {
    link.classList.add("active");
  }
});
